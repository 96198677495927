import { LOCAL_VAR } from './../../../core/constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {
  color = 'defaultdark';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  // loggedInUserType: any;
  // isuserLoggedIn: any;
  public config: PerfectScrollbarConfigInterface = {};
  public isDealCreatePage = false;
  loggedInUserType: any = null;
  user: Authentication.User;
  private unsubscribe: Subject<void> = new Subject();
  agent: any = null;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.authenticationService.credentials$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((credentials: Authentication.Credentials) => {
        this.user = credentials.user;
      });
    this.loggedInUserType = this.user.userRoleId.slug;
    this.agent = localStorage.getItem(LOCAL_VAR.selectedAgent);
    // this.isuserLoggedIn = localStorage.getItem('isLoogdedin');
    if (!this.agent || this.agent === 'null') {
      if (this.loggedInUserType === 'admin') {
        if (this.router.url !== '/agents/create') {
          this.router.navigate(['/agents'], { replaceUrl: true });
        }
      } else {
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      }
    }
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        if (event && event.urls && event.urls[0].url === '/deals/create') {
          this.isDealCreatePage = true;
        } else {
          this.isDealCreatePage = false;
        }
      });
  }

  ngOnInit() {
    if (this.router.url === '/deals/create') {
      this.isDealCreatePage = true;
    } else {
      this.isDealCreatePage = false;
    }
  }

  ngOnDestroy() {
    console.log('ngOnDestory');
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
