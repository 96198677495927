import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealsService {
  dealData: any = null;
  dealData$: EventEmitter<any> = new EventEmitter<any>();
  constructor(protected httpClient: HttpClient) {}

  getDeals(agentId: String) {
    return this.httpClient.get(`/agent/${agentId}/deals`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getDealById(agentId: string, dealId: string) {
    this.httpClient
      .get(`/agent/${agentId}/deal/${dealId}`)
      .subscribe((body: any) => {
        if (body) {
          this.dealData = body.records;
          this.dealData$.emit(this.dealData);
        } else {
          this.dealData = null;
        }
      });
  }

  getDealDataById(agentId: string, dealId: string) {
    return this.httpClient.get(`/agent/${agentId}/deal/${dealId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getDealDataDetails(agentId: string, dealId: string) {
    return this.httpClient.get(`/agent/${agentId}/deal/${dealId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getDealsByFilter(agentId: String, payload: any) {
    return this.httpClient
      .post(`/agent/${agentId}/dealsbyfilter`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  deleteDeal(agentId: String, dealId: string) {
    return this.httpClient.delete(`/agent/${agentId}/deal/${dealId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  acceptRequest(agentId: String, dealId: string, payload: any) {
    return this.httpClient
      .put(`/agent/${agentId}/deal/${dealId}/acceptrequest`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  rejectRequest(agentId: String, dealId: string, payload: any) {
    return this.httpClient
      .put(`/agent/${agentId}/deal/${dealId}/rejectrequest`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  stepSubmit(agentId: String, stepNo: any, payload: any, isAddMode?: boolean) {
    if (stepNo === 1 && isAddMode) {
      return this.httpClient
        .post(`/agent/${agentId}/deal/step${stepNo}`, payload)
        .pipe(
          map((body: any) => {
            return body;
          })
        );
    } else {
      return this.httpClient
        .put(`/agent/${agentId}/deal/step${stepNo}`, payload)
        .pipe(
          map((body: any) => {
            return body;
          })
        );
    }
  }

  changeRequest(dealId: any, payload: any) {
    return this.httpClient.post(`/deal/${dealId}/changerequest`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getProductsByCategory(catName: string) {
    return this.httpClient.get(`/productlist/${catName}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getDealSelectedProducts(agentId: string, dealId: string) {
    return this.httpClient
      .get(`/agent/${agentId}/deal/${dealId}/selectedproduct`)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  getchangeRequestData(dealId: any, chageRequestId: any) {
    return this.httpClient
      .get(`/deal/${dealId}/changerequest/${chageRequestId}`)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  mergeChangeRequest(dealId: any, chageRequestId: any) {
    return this.httpClient
      .put(`/deal/${dealId}/changerequest/${chageRequestId}/merge`, {})
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  rejectChangeRequest(dealId: any, chageRequestId: any, payload: any) {
    return this.httpClient
      .put(`/deal/${dealId}/changerequest/${chageRequestId}/reject`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  getChangeRequestList(dealId: any) {
    return this.httpClient.get(`/deal/${dealId}/changerequestlist`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
