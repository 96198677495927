import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GroupsService } from '@app/core/service/groups.service';
import { UsersService } from '@app/core/service/users.service';
import { AgentsService } from '@app/core/service/agents.service';
import { DealsService } from '@app/core/service/deals.service';

const services: any = {
  GroupsService: GroupsService,
  UsersService: UsersService,
  AgentsService: AgentsService,
  DealsService: DealsService
};

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input()
  title? = 'Are you sure want to delete?';

  @Input()
  body? = 'Click on Yes to delete';

  @Input()
  okBtn? = 'Yes';

  @Input()
  cancelBtn? = 'Cancel';

  @Input()
  service? = '';

  @Input()
  method? = '';

  @Input()
  payload?: any[] = [];

  isLoading = false;

  serviceProvider: any;

  constructor(
    public activeModal: NgbActiveModal,
    private injector: Injector,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.service && services.hasOwnProperty(this.service)) {
      this.serviceProvider = this.injector.get(services[this.service]);
    } else {
      console.error(`${this.service} does not exist`);
    }
  }

  onSuccess() {
    if (this.serviceProvider && this.method) {
      this.isLoading = true;
      this.serviceProvider[this.method](...this.payload)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            if (response === null) {
              response = true;
            }
            this.activeModal.close(response);
          },
          (error: any) => {
            this.isLoading = false;
            console.log('Confirmation modal error:', error);
          }
        );
    } else {
      this.activeModal.close();
      // this.router.navigate(['/dashboard']);
    }
  }
}
