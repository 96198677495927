{{ label }}
<sup
  *ngIf="
    control &&
    control.validator &&
    control.validator('') &&
    control.validator('').required
  "
  class="required"
  >*</sup
>
