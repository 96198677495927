import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
import {
  ApiPrefixInterceptor,
  ErrorHandlerInterceptor,
  HttpService
} from '@app/core/http';
import { UAParserService } from '@app/core/lib';
import { LocalStorageService } from '@app/core/local-storage.service';
import { RouteReusableStrategy } from '@app/core/route-reusable-strategy';
import { ErrorMessageService, UtilService } from '@app/core/service';
import { ToastrModule } from 'ngx-toastr';

import { AdminGuard } from './authentication/admin.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { NavigationService } from './service/navigation.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ToastrModule.forRoot()
  ],
  declarations: [],
  providers: [
    LocalStorageService,
    AuthenticationGuard,
    AdminGuard,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    UAParserService,
    UtilService,
    ErrorMessageService,
    AuthenticationService,
    NavigationService,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true
    },
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {}
