import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  constructor(protected httpClient: HttpClient) {}

  getAgents() {
    return this.httpClient.get(`/agents`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAgentDetail(agentId: String) {
    return this.httpClient.get(`/agent/${agentId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  createAgent(payload: any) {
    return this.httpClient.post(`/agent`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateAgent(agentId: String, payload: any) {
    return this.httpClient.put(`/agent/${agentId}`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  deleteAgent(agentId: String) {
    return this.httpClient.delete(`/agent/${agentId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
