<div class="modal-header">
  <h4 class="modal-title" [innerHTML]="title | safeHtml"></h4>
</div>
<div class="modal-body">
  <div [innerHTML]="body | safeHtml"></div>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="cancelBtn && cancelBtn.length" class="btn btn-secondary" [disabled]="isLoading" (click)="activeModal.dismiss('Cross click')">{{cancelBtn}}</button>
  <button class="btn btn-primary" (click)="onSuccess()" [disabled]="isLoading" *ngIf="okBtn && okBtn.length">
    <app-button-loader [isLoading]="isLoading" [label]="okBtn"></app-button-loader>
  </button>
</div>
