import { LOCAL_VAR } from './../../core/constants';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { NavigationService } from '@app/core/service/navigation.service';
import { ConfirmationComponent } from '../modals/confirmation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService, CONFIRMATION_MSGS } from '@app/core';

declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
  loggedInUserType: any = null;
  pageInfo: any;
  agentName = '';
  selectedAgent: any = null;
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private modalService: NgbModal,
    private utilService: UtilService
  ) {
    this.user = this.authenticationService.credentials.user;
    this.loggedInUserType = this.user.userRoleId.slug;
    this.authenticationService.credentials$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((credentials: Authentication.Credentials) => {
        this.user = credentials.user;
        this.loggedInUserType = this.user.userRoleId.slug;
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        $('.mobile-menu-container').removeClass('show');
        this.pageInfo = event;
        this.selectedAgent = localStorage.getItem(LOCAL_VAR.selectedAgent);
        this.selectedAgent = JSON.parse(this.selectedAgent);
        if (this.router.url === '/agents') {
          this.agentName = '';
        }
      });

    this.navigationService.navigationHandler$.subscribe((value: any) => {
      this.agentName = value;
    });
  }
  name: string;
  user: Authentication.User;
  merchant: Authentication.Merchant | null;
  queryParams: any;
  isMobileMenuEnabled = false;
  public config: PerfectScrollbarConfigInterface = {};
  private unsubscribe: Subject<void> = new Subject();

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  ngOnInit() {
    $(function() {
      $('.page-wrapper').on('click', function() {
        $('.mobile-menu-container').removeClass('show');
      });
      $('.menutoggler').on('click', function() {
        $('.mobile-menu-container').toggleClass('show');
      });
    });
  }

  ngAfterViewInit() {
    const set = function() {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      const topOffset = 0;
      if (width < 1170) {
        $('#main-wrapper').addClass('mini-sidebar');
      } else {
        $('#main-wrapper').removeClass('mini-sidebar');
      }
    };
    $(window).ready(set);
    $(window).on('resize', set);

    $('.search-box a, .search-box .app-search .srh-btn').on(
      'click',
      function() {
        $('.app-search').toggle(200);
      }
    );

    $('body').trigger('resize');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  logout() {
    // localStorage.removeItem('userType');
    localStorage.removeItem(LOCAL_VAR.selectedAgent);
    // this.router.navigate(['/login'], { replaceUrl: true });
    this.authenticationService.logout(true).subscribe(res => {
      this.router.navigate(['/login']);
    });
  }

  onChangeSwitchAgent(e: any) {
    if (e.target.value === 'all') {
      localStorage.setItem(LOCAL_VAR.selectedAgent, null);
      this.selectedAgent = null;
      this.router.navigate(['/agents']);
    } else {
      this.selectedAgent = e.target.value;
      localStorage.setItem(LOCAL_VAR.selectedAgent, e.target.value);
      this.onSwitchAgentConfirmationPopup();
    }
  }

  onSwitchAgentConfirmationPopup() {
    const modalRef = this.modalService.open(ConfirmationComponent);

    modalRef.componentInstance.title = this.utilService.objectResolver(
      'switchAgent.confirmation.title',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.body = this.utilService.objectResolver(
      'switchAgent.confirmation.content',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.okBtn = this.utilService.objectResolver(
      'switchAgent.confirmation.okBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.cancelBtn = this.utilService.objectResolver(
      'switchAgent.confirmation.cancelBtn',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.service = this.utilService.objectResolver(
      'switchAgent.confirmation.service',
      CONFIRMATION_MSGS
    );
    modalRef.componentInstance.method = this.utilService.objectResolver(
      'switchAgent.confirmation.method',
      CONFIRMATION_MSGS
    );

    // if (result) {

    // }
  }
}
