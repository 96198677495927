import { LOCAL_VAR } from './../../../core/constants';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DealsService } from '@app/core/service/deals.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reject-request',
  templateUrl: './reject-request.component.html',
  styleUrls: ['./reject-request.component.scss']
})
export class RejectRequestComponent implements OnInit {
  @Input()
  dealId? = '';

  @Input()
  changeRequestId? = '';

  @Input()
  rejectRequestFrom? = '';

  agent: any = null;
  rejectForm: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    private dealsService: DealsService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.agent = localStorage.getItem(LOCAL_VAR.selectedAgent);
    this.agent = JSON.parse(this.agent);
    this.createForm();
  }

  ngOnInit() {}

  createForm() {
    this.rejectForm = this.formBuilder.group({
      message: ['', [Validators.required]]
    });
  }

  rejectRequest() {
    if (this.rejectRequestFrom === 'changeRequest') {
      this.dealsService
        .rejectChangeRequest(
          this.dealId,
          this.changeRequestId,
          this.rejectForm.value
        )
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(
          (response: any) => {
            if (response) {
              this.toastrService.success('request rejected successfully');
              this.activeModal.close(response);
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 500);
            }
          },
          error => {
            this.toastrService.warning('something went Wrong');
            this.activeModal.close();
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 500);
          }
        );
    } else {
      this.dealsService
        .rejectRequest(this.agent._id, this.dealId, this.rejectForm.value)
        .subscribe((response: any) => {
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    }
  }
}
