import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseAPIClass } from '../class/baseAPI.class';

@Injectable({
  providedIn: 'root'
})
export class GroupsService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getAgentGroups(agentId: String) {
    return this.httpClient.get(`/agent/${agentId}/groups`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getAgentGroupDetail(agentId: String, groupId: String) {
    return this.httpClient.get(`/agent/${agentId}/group/${groupId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  createAgentGroup(agentId: string, payload: any) {
    return this.httpClient.post(`/agent/${agentId}/group`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateAgentGroup(agentId: String, groupId: String, payload: any) {
    return this.httpClient
      .put(`/agent/${agentId}/group/${groupId}`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  deleteAgentGroup(agentId: String, groupId: String) {
    return this.httpClient.delete(`/agent/${agentId}/group/${groupId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
