import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/local-storage.service';
import { ErrorMessageService } from '@app/core/service';
import { UtilService } from '@app/core/service/util.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LOCAL_VAR } from './../constants';

const credentialsKey = LOCAL_VAR.credentials;

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private utilService: UtilService,
    private errorMessageService: ErrorMessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.utilService.clearMissedError();
    return next
      .handle(request)
      .pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(
    response: HttpResponse<any>
  ): Observable<HttpEvent<any>> {
    if (response.status === 401) {
      this.localStorageService.clearItem(credentialsKey);
      this.router.navigate(['/auth/login'], {
        replaceUrl: true
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 200);
    } else if (response.status === 400) {
      const errorResponse: any = response;
      if (errorResponse.error) {
        if (
          errorResponse.error.validation &&
          errorResponse.error.validation.errors
        ) {
          this.errorMessageService.clear();
          Object.keys(errorResponse.error.validation.errors).forEach(
            (key: string) => {
              this.errorMessageService.set(
                errorResponse.error.validation.errors[key],
                key,
                response.url
              );
            }
          );
        } else {
          this.utilService.clearMissedError();
          this.utilService.setMissedError(errorResponse.error.message);
          // this.utilService.showNotification(
          //   {
          //     error: errorResponse.error.error,
          //     message: errorResponse.error.message,
          //     type: 'warning',
          //     timer: 1000,
          //   }
          // )
        }
        console.log(errorResponse.error.message, 'Message');
        // console.log(errorResponse.error.error)
      }
    } else if (response.status === 500) {
      const errorResponse: any = response;
      this.utilService.clearMissedError();
      this.utilService.setMissedError(errorResponse.error.message);
    }

    if (!environment.production) {
      // Do something with the error
      // console.error('Request error', response);
    }
    throw response;
  }
}
