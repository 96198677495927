import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private _httpClient: HttpClient) {}

  getAgentUsers(agentId: string) {
    return this._httpClient.get(`/agent/${agentId}/users`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  getUserDetail(agentId: string, userId: string) {
    return this._httpClient.get(`/agent/${agentId}/user/${userId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  createAgentUser(agentId: string, payload: any) {
    return this._httpClient.post(`/agent/${agentId}/user`, payload).pipe(
      map((body: any) => {
        return body;
      })
    );
  }

  updateAgentUser(agentId: string, userId: string, payload: any) {
    return this._httpClient
      .put(`/agent/${agentId}/user/${userId}`, payload)
      .pipe(
        map((body: any) => {
          return body;
        })
      );
  }

  deleteAgentUser(agentId: string, userId: string) {
    return this._httpClient.delete(`/agent/${agentId}/user/${userId}`).pipe(
      map((body: any) => {
        return body;
      })
    );
  }
}
