import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }

    let queryParams = {};
    if (state.root.queryParams.invitationCode) {
      queryParams = { invitationCode: state.root.queryParams.invitationCode };
    }

    console.log('Not authenticated, redirecting...');
    this.router.navigate(['/login'], {
      replaceUrl: true,
      queryParams: queryParams
    });
    return false;
  }
}
