import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { ErrorMessageService } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  pageInfo: any;
  liveMode: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private errorMessageService: ErrorMessageService
  ) {
    // REF for unsubscribe: https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.errorMessageService.clear();

        const prefix = '';
        // if (!this.liveMode && event && event.testLabel === true) {
        //   prefix = '';
        // }

        this.titleService.setTitle(
          `${prefix}${event['title'] ? event['title'] : 'Dowc'}`
        );
        setTimeout(() => {
          this.titleService.setTitle(
            `${prefix}${event['title'] ? event['title'] : 'Dowc'}`
          );
        }, 500);
        this.pageInfo = event;
      });
  }
}
